export const SOCIAL_LINK_TYPES = {
    FACEBOOK: 'FACEBBOK',
    INSTAGRAM: 'INSTAGRAM',
    SPOTIFY: 'SPOTIFY',
    YOUTUBE: 'YOUTUBE',
    EMAIL: 'EMAIL',
    BANDCAMP: 'BANDCAMP',
    TWITTER: 'TWITTER',
    CUSTOM: 'CUSTOM',
    HIGHLIGHT: 'HIGHLIGHT',
    SHOWS: 'SHOWS',
    APPLE_MUSIC: 'APPLE_MUSIC',
    TIKTOK: 'TIKTOK',
}