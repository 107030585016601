import { biography, socialLinks } from '../../config/content'

import React from 'react';
import SocialLink from '../SocialLink';

const LinkTree = () => {
    return <div className="linktree">
        <img src="/logo.png" className="logo" />
        {biography && <p className="bio">{biography}</p>}

        <div className="linktree-social-links">
            {socialLinks?.map(({ url, type, label, ...rest }) => <SocialLink url={url} type={type} label={label} {...rest} key={url} />)}
        </div>
    </div>


}

export default LinkTree;