import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import React from 'react'
import { SOCIAL_LINK_TYPES } from '../SocialLink/socialLinkConstants';
import { socialLinks } from '../../config/content';

const FooterIconLink = ({type}) => {
    const url = socialLinks.find(({type: t}) => t === type)?.url

    if(!url){
        return null;
    }

    let Icon;

    switch(type){
        case SOCIAL_LINK_TYPES.FACEBOOK: Icon = FacebookIcon; break;
        case SOCIAL_LINK_TYPES.INSTAGRAM: Icon = InstagramIcon; break;
        default: return null;
    }

    return <a href={url} target="_blank" className="footer-icon" rel="noreferrer"><Icon /></a>
}

const Footer = () => <small className="footer">
    <p>© {new Date().getFullYear()} Airquake</p>
    <div className="social-links">
        <FooterIconLink type={SOCIAL_LINK_TYPES.INSTAGRAM} />
        <FooterIconLink type={SOCIAL_LINK_TYPES.FACEBOOK} />
    </div>
</small>


export default Footer;