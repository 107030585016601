import './App.scss';
import 'animate.css';

import Footer from './Components/Footer';
import HttpsRedirect from 'react-https-redirect';
import LinkTree from './Components/LinkTree';
import React from 'react';

function App() {
  return (
    <HttpsRedirect>
      <div className="app">
        <LinkTree />
        <Footer />
      </div>
    </HttpsRedirect>
  );
}

export default App;
