import { SOCIAL_LINK_TYPES } from "../Components/SocialLink/socialLinkConstants";

export const socialLinks = [
    {
        type: SOCIAL_LINK_TYPES.SHOWS,
        label: 'Upcoming events',
    },
    {
        url: 'https://www.instagram.com/airquakeband/',
        type: SOCIAL_LINK_TYPES.INSTAGRAM,
        label: 'Instagram',
    },
    {
        url: 'https://www.facebook.com/airquakeband',
        type: SOCIAL_LINK_TYPES.FACEBOOK,
        label: 'Facebook',
    },
    {
        url: 'https://open.spotify.com/artist/2K90JFM3TqdLU8o8SIOhQF?si=-ZI2Av6FQPOV_-dF9pqSwQ',
        type: SOCIAL_LINK_TYPES.SPOTIFY,
        label: 'Spotify',
    },
    {
        url: 'https://music.apple.com/us/artist/airquake/958194710',
        type: SOCIAL_LINK_TYPES.APPLE_MUSIC,
        label: 'Apple Music',
    },
    {
        url: 'https://www.youtube.com/airquakeband',
        type: SOCIAL_LINK_TYPES.YOUTUBE,
        label: 'Youtube',
    },
    {
        url: 'https://airquake.bandcamp.com',
        type: SOCIAL_LINK_TYPES.BANDCAMP,
        label: 'Bandcamp'
    },
    {
        url: 'https://www.tiktok.com/@airquake1',
        type: SOCIAL_LINK_TYPES.TIKTOK,
        label: 'TikTok',
    },
    {
        url: 'https://twitter.com/airquakeband',
        type: SOCIAL_LINK_TYPES.TWITTER,
        label: 'Twitter'
    },
    {
        url: 'mailto:contact@airquake.ch',
        type: SOCIAL_LINK_TYPES.EMAIL,
        label: 'Contact us'
    },
];

export const biography = "Airquake is a prog rock band from Switzerland. Its music is inspired by many different genres of music to create an interesting brand of progressive rock with catchy melodies and riffs, complicated rythmic and adventurous structures.";

export const shows = [
    {
        date: '10.11.2023',
        venue: "L'Amalgame",
        city: 'Yverdon-les-Bains (CH)',
        url: 'https://www.amalgameclub.ch/programme/ultra-local-streaker-hypsign-vernissage-airquake/',
        tickets: 'https://www.petzi.ch/fr/events/53297-amalgame-streaker-hypsign-vernissage-airquake/tickets/#ticket-84739'  
    },
    {
        date: '05.10.2024',
        venue: "Les Citrons Masqués",
        city: 'Yverdon-les-Bains (CH)',
        url: 'https://citronsmasques.ch/events/loretta-gun-airquake/',
    },
]