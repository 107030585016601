import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons'
import { shows } from '../../config/content'

const Show = ({date, venue, city, url, tickets}) => {
    const [day, month, year] = date.split('.');
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    return <a className="show" target="_blank" href={url} rel="noreferrer">
        <div className="date">
            <div className="day">{day}</div>
            <div className="month">{months[parseInt(month)-1]}</div>      
        </div>
        <div className="location">
            <div className="venue">{venue}</div>
            <div className="city">{city}</div>
        </div>
        {tickets && <div className="tickets">
            <a href={tickets} target="_blank" rel="noreferrer"><FontAwesomeIcon className="ticket-logo" icon={faTicketAlt} /><span>
                Tickets
                </span>
                </a>
        </div>}
    </a>
}

const Shows = () => {
    const today = new Date();

    const upcoming = shows?.filter(( {date:d }) => {
        const [day,month,year] = d.split('.'); 
        const date = new Date();
        date.setFullYear(year, parseInt(month)-1, day);
        return today.getTime() - date.getTime() <= 0;
    });

    return <div className="shows animate__animated animate__fadeIn">
        {upcoming?.map(show => <Show {...show} />)}
    </div>
}

export default Shows;