import React, { useState } from 'react'
import { getButtonProps, theme } from './socialLinkHelpers';

import Button from '@mui/material/Button';
import { SOCIAL_LINK_TYPES } from './socialLinkConstants';
import Shows from '../Shows';
import { ThemeProvider } from '@emotion/react';
import { shows } from '../../config/content'

const SocialLink = ({ url, label, type }) => {
    const [displayShows, setDisplayShows] = useState(false);
    const today = new Date();

    const upcomingShows = shows?.filter(( {date:d }) => {
        const [day,month,year] = d.split('.'); 
        const date = new Date();
        date.setFullYear(year, parseInt(month)-1, day);
        return today.getTime() - date.getTime() <= 0;
    });

    const toggleDisplayShows = () => setDisplayShows(!displayShows);
    const [href] = useState(url)
    const buttonProps = getButtonProps(type, toggleDisplayShows)

    return <ThemeProvider theme={theme}>
        {type === SOCIAL_LINK_TYPES.SHOWS && !upcomingShows?.length ? null : <Button href={href} {...buttonProps}><span>{label}</span></Button>}
        {displayShows && <Shows />}
    </ThemeProvider>

}

export default SocialLink;