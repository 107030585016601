import { faApple, faBandcamp, faFacebook, faInstagram, faSpotify, faTiktok, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faBullhorn, faCalendar, faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SOCIAL_LINK_TYPES } from './socialLinkConstants';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        main: {
            main: '#00d4ad',
            dark: '#49ffdd'
        },
    },
});

const getIcon = type => {
    switch (type) {
        case SOCIAL_LINK_TYPES.FACEBOOK: return () => <FontAwesomeIcon icon={faFacebook} />;
        case SOCIAL_LINK_TYPES.INSTAGRAM: return () => <FontAwesomeIcon icon={faInstagram} />;
        case SOCIAL_LINK_TYPES.SPOTIFY: return () => <FontAwesomeIcon icon={faSpotify} />;
        case SOCIAL_LINK_TYPES.YOUTUBE: return () => <FontAwesomeIcon icon={faYoutube} />;
        case SOCIAL_LINK_TYPES.EMAIL: return () => <FontAwesomeIcon icon={faEnvelope} />;
        case SOCIAL_LINK_TYPES.BANDCAMP: return () => <FontAwesomeIcon icon={faBandcamp} />;
        case SOCIAL_LINK_TYPES.TWITTER: return () => <FontAwesomeIcon icon={faTwitter} />;
        case SOCIAL_LINK_TYPES.CUSTOM: return () => <FontAwesomeIcon icon={faGlobe} />;
        case SOCIAL_LINK_TYPES.HIGHLIGHT: return () => <FontAwesomeIcon icon={faBullhorn} />;
        case SOCIAL_LINK_TYPES.SHOWS: return () => <FontAwesomeIcon icon={faCalendar} />;
        case SOCIAL_LINK_TYPES.APPLE_MUSIC: return () => <FontAwesomeIcon icon={faApple} />;
        case SOCIAL_LINK_TYPES.TIKTOK: return () => <FontAwesomeIcon icon={faTiktok} />;
        default: return () => null;
    }
}

export const getButtonProps = (type, callback) => {
    const Icon = getIcon(type)

    let props = {
        color: 'main',
        startIcon: <Icon />,
        variant: 'outlined',
        style:
        {
            marginBottom: '16px',
        },
        size: 'large'
    };

    switch (type) {
        case SOCIAL_LINK_TYPES.HIGHLIGHT:
            props = {
                ...props,
                color: 'main',
                variant: 'contained',
                style: {
                    ...props.style,
                    color: '#fff',
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    marginBottom: '24px'
                },
                className: 'animate__animated animate__pulse animate__infinite'
            };
            break;
         case SOCIAL_LINK_TYPES.SHOWS: 
            props = {
                ...props,
                href: undefined,
                onClick: callback,
            };
            break;
        default: break;
    }

    return props;
}